import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import Layout from './components/Layout'
import Team from './pages/Team'

function App() {
  const location = useLocation();
  return (
    <Layout>
      <Routes location={location}>
        <Route path="/team" element={
          <Team />
        }/>
        <Route path="*" element={<Navigate replace to="/team" />} />
      </Routes>
    </Layout>
  );
}

export default App;
