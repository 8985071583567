import { utils } from 'ethers'
import { useContractFunction  } from '@usedapp/core';
import { Contract } from '@ethersproject/contracts'
import TEAMS_ABI from '../../contracts/teams.json'
import { TEAMS_ADDRESS } from '../../config';

const useJoinTeam = () => {
  const contract = new Contract(TEAMS_ADDRESS, new utils.Interface(TEAMS_ABI))
  const { state, send } = useContractFunction(contract, 'joinTeam', { transactionName: 'Join Team' })

  return { state, send }
}

export default useJoinTeam