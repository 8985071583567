import { useLocation } from 'react-router-dom'
import classes from './index.module.css'
import Sidebar from './Sidebar'
import Header from './Header'
import { Toaster } from 'react-hot-toast'
import { useEffect, useState } from 'react'

const Layout = ({ children }) => {
  const location = useLocation();
  let arr = ['/dashboard', '/swap', '/account', '/stake', '/liberate'];
  arr.indexOf(location.pathname)
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false)
  }, [location])

  return (
    <div className="flex">
      <Toaster position="bottom-center"/>
      {/* <Sidebar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/> */}
      <div className={`${classes.main} relative w-full h-screen`}>
      {/* <div className={`${classes.main} relative w-full ml-72 h-screen bg-cover bg-no-repeat`} style={{'background-image': `url('/bg/${arr.indexOf(location.pathname) + 1}.png')`}}> */}
        <div className="w-full flex justify-center items-center">
          <div className="w-11/12 md:w-3/4 relative flex flex-col justify-center items-center z-10">
            <Header setIsMenuOpen={setIsMenuOpen}/>
            { children }
          </div>
        </div>
      </div>
      <div className={`${isMenuOpen ? "absolute" : "hidden"} z-40 h-screen w-screen bg-black opacity-40`} onClick={() => setIsMenuOpen(false)}></div>
    </div>
  )
}
 
export default Layout