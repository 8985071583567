export const TEAMS_ADDRESS = "0xf744B8Ddb3593c532bD1854e70e504382D6179f2"
export const MARKETING_WALLET = "0x1e729c69aA233DD9f79c9132375AC4ECBD4c40d4"

export const JSON_RPC = "https://bsc-dataseed.binance.org"
export const CHAIN_ID = 56
export const CHAIN_NAME = 'Binance Smart Chain'
export const EXPLORER = "https://bscscan.com/"

export const NATIVE_CURRENCY_NAME = "Binance"
export const NATIVE_CURRENCY_SYMBOL = "BNB"
export const NATIVE_CURRENCY_DECIMALS = 18