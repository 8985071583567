import { utils } from 'ethers'
import { useCall, useEthers } from '@usedapp/core';
import { Contract } from '@ethersproject/contracts'
import TEAMS_ABI from '../../contracts/teams.json'
import { TEAMS_ADDRESS } from '../../config';

const useGetReferrer = () => {
  const { account } = useEthers()
  const { value, error } = useCall(TEAMS_ADDRESS && account && {
    contract: new Contract(TEAMS_ADDRESS, new utils.Interface(TEAMS_ABI)),
    method: 'getReferrer',
    args: [account]
  }) ?? { value : ['NONE'] }
  if(error) {
    console.error(error.message)
    return undefined
  }
  return value?.[0]
}

export default useGetReferrer