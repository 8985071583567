import classes from './Header.module.css'
import Web3ModalButton from './Web3ModalButton'
import pic from "../../assets/images/logo.svg"
import menu from "../../assets/svg/menu.svg"

const Header = ({setIsMenuOpen}) => {
  return (
    <>
      <div className={`${classes.card} hidden lg:flex absolute bg-black px-4 h-12 left-0 top-10 text-cyany justify-center items-center`}>${0.00}</div>
      <Web3ModalButton className={`${classes.card} flex  absolute bg-black px-4 h-12 right-0 top-10 text-cyany justify-center items-center cursor-pointer gap-2`}/>
    </>
  )
}

export default Header