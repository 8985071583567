import { useState, useEffect } from 'react'
import { toast } from 'react-hot-toast'

import { utils } from 'ethers'
import { CHAIN_ID, TOKEN_ADDRESS, BUSD_ADDRESS, MARKETING_WALLET } from '../config'
import classes from './Dashboard.module.css'
import { useEthers } from '@usedapp/core';
import useGetReferrer from '../hooks/read/useGetReferrer';
import useJoinTeam from '../hooks/write/useJoinTeam';
import useGetReferrals from '../hooks/read/useGetReferrals';
import useTeamCompound from '../hooks/write/useTeamCompound';
import useTeamClaim from '../hooks/write/useTeamClaim';

const toastOptions = {
  style: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '999rem',
    background: '#222222',
    border: '1px solid #282828',
    color: '#fff',
  },
}

const Account = () => {
  const { account } = useEthers()
  const getReferrer = useGetReferrer(account)

  const referrals = useGetReferrals()
  const joinTeam = useJoinTeam()
  const compoundRewards = useTeamCompound()
  const claimRewards = useTeamClaim()

  const [newReferrer, setNewReferrer] = useState()

  const handleChangeReferrer = () => {
    if (getReferrer == MARKETING_WALLET) {
      joinTeam.send(newReferrer, {value : 0})
    } else {
      joinTeam.send(newReferrer, {value : utils.parseEther('0.25')})
    }
  }

  useEffect(() => {
    if (joinTeam.state.status == "Exception" || joinTeam.state.status == "Fail") {
      toast.error(joinTeam.state.errorMessage.split(':')[joinTeam.state.errorMessage.split(':').length - 1].trim(), toastOptions)
    } else if (joinTeam.state.status == "Success") {
      toast.success("Successfully changed!", toastOptions)
    }
  }, [joinTeam.state])

  useEffect(() => {
    if (compoundRewards.state.status == "Exception" || compoundRewards.state.status == "Fail") {
      toast.error(compoundRewards.state.errorMessage.split(':')[compoundRewards.state.errorMessage.split(':').length - 1].trim(), toastOptions)
    } else if (compoundRewards.state.status == "Success") {
      toast.success("Successfully compounded!", toastOptions)
    }
  }, [compoundRewards.state])

  useEffect(() => {
    if (claimRewards.state.status == "Exception" || claimRewards.state.status == "Fail") {
      toast.error(claimRewards.state.errorMessage.split(':')[claimRewards.state.errorMessage.split(':').length - 1].trim(), toastOptions)
    } else if (claimRewards.state.status == "Success") {
      toast.success("Successfully claimed!", toastOptions)
    }
  }, [claimRewards.state])

  return (
      <div className="w-full h-screen relative flex flex-col justify-center items-center -z-10 gap-8">
        <h1 className={`${classes.heading} font-pricedown text-4xl md:text-6xl text-white relative pb-4 mb-4`}>team</h1>
        <div className="w-full flex justify-center gap-8">
          <div className={`${classes.card} relative bg-darky text-white text-center flex flex-col w-full md:w-4/5 2xl:w-1/2 p-6`}>
            <div className="flex w-full flex-col">
              <div className="flex justify-between">
                <div className="opacity-50">Your boss</div>
              </div>
              <div className="flex items-center justify-center border-b border-pinky">
                <input className=" h-full z-20 w-full flex-1 outline-none bg-transparent py-3 opacity-100 disabled:cursor-not-allowed" placeholder={getReferrer} value={newReferrer} onChange={(e) => setNewReferrer(e.target.value)} />
              </div>
            </div>
            <div className="flex justify-center items-center w-full py-6">
              <button className={`${classes.button} font-medium w-3/4 py-2 rounded-sm relative z-10`} onClick={handleChangeReferrer}>Change</button>
            </div>
            <div className="flex flex-col w-full text-sm">
              <div className="w-full flex justify-between">
                <p className="opacity-50">Members in your team</p>
                <p>{referrals.length}</p>
              </div>
              <div className="w-full flex-col text-left">
                <p className="opacity-50">Team members</p>
                <div className="flex flex-col max-h-24 overflow-auto">
                  {referrals.length > 0 ? referrals.map(el => (
                    <p>{el}</p>
                  )) : <p>No team members yet</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Account;